<template>
  <layout-custom>
      
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
        <showAt breakpoint="large">
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </showAt>
        <showAt breakpoint="mediumAndBelow">
            <navbar-mobile :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </showAt>
    </template>
 
  </layout-custom>
</template>

<script>
import LayoutCustom from '@core/layouts/layout-custom/LayoutCustom.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import NavbarMobile from '../components/Navbar.mobile.vue'

import { showAt, hideAt } from 'vue-breakpoints'

export default {
        
  methods: {
      pickerWillHide() {
          this.$router.replace( this.$route.path );
      }
  },      
        
  watch: {
        "$route.query.act" : function( action ) {
          
            switch(action) {
              case 'currency': 
                  this.showCurrencyModal = true; 
                  break;
            }
            
            if( this.showCurrencyModal ) {
                this.showCurrencyModal = false;
            }
            
        },
  },      
        
  components: {
    // AppCustomizer,
    LayoutCustom,
    Navbar,
    NavbarMobile,
    showAt
  },
  data() {
    return {
        showCurrencyModal: false,
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
