<template>
    <div>
        <div id="bottom-nav" class="bottom-nav-bar d-flex justify-content-between">
            <bottom-nav-menu-item 
                v-for="item in bottomNav" 
                :key="item.header || item.title"
                :item="item" 
            />    
            <b-link v-b-toggle.sidebar-right class="d-flex align-items-center">
                <div :class=" isSidebarActive ? 'menu-icon icon-more-active' : 'menu-icon icon-more'"></div>
            </b-link>
        </div>
        
        <b-sidebar
            id="sidebar-right"
            v-model='isSidebarActive'
            no-header
            no-header-close
            right
            backdrop
            shadow
        >
            <div style="padding: 17px 16px; height: 100%; padding-bottom: 56px;">
                <div class="text-black" style="font-size: 20px; font-weight: 600; padding: 0 16px; margin-bottom: 16px;">
                    Ещё
                </div>
                <div class="sidebar-menu-list d-flex flex-column justify-content-between" style="height: calc(100% - 60px);"> 
                    <ul class="navigation navigation-main">
                        <template v-for="item in rightSideNav">
                            <template v-if="item.children">
                                <vertical-nav-menu-link 
                                    :key="item.header || item.title"
                                    :item="item"
                                    @click="toggleCollapse(item)"
                                />
                                <b-collapse v-model="item.visible">
                                    <vertical-nav-menu-link 
                                        v-for="child in item.children"
                                        :key="child.header || child.title"
                                        :item="child"
                                    />
                                </b-collapse>   
                            </template>
                            <vertical-nav-menu-link v-else
                                :key="item.header || item.title"
                                :item="item"
                            />
                        </template>
                    </ul>
                    
                    <div>
                        <div class='separator'></div>
                        <div class=" d-flex justify-content-between" style="padding: 12px 0px 12px 16px;">
                            <div class="text-black m-0">
                                Включить темную тему
                            </div>
                            <div >
                                <b-form-checkbox
                                    class="site-mode custom-control-primary p-0 m-0"
                                    style="right: 44px;"
                                    name="dark-mode"
                                    v-model='skin'
                                    value='dark'
                                    unchecked-value='light'
                                    switch
                                  >
                                </b-form-checkbox>
                            </div>
                        </div>
                        <div class='separator'></div>
                        <div v-ripple class=" d-flex justify-content-between" style="padding: 12px 0px 12px 16px;">
                            <div class="text-primary" @click="logout">
                                Выйти из аккаунта
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </b-sidebar>
        
    </div>
</template>

<script>
    
    const TWEEN = require('@tweenjs/tween.js')

    import store from '@/store'

    import navMenuItems from '@/navigation/bottom'
    import BottomNavMenuItem from "./bottom-nav-menu-item/bottom-nav-menu-item"
    import VerticalNavMenuLink from "./vertical-nav-menu-link/VerticalNavMenuLink"
    import useAppConfig from '@core/app-config/useAppConfig'

    export default {

        data() {
            return {
                navMenuItems,
                isSidebarActive: false,
                skin: useAppConfig().skin.value
            }
        },
        methods: {
            toggleCollapse( target ) {
                
                this.rightSideNav.forEach( 
                   item => { if( target.title !== item.title ) item.visible = false;  }
                );
        
                target.visible = !target.visible;
                
            },

            logout() {
                this.$request.post('sessions.finish').then(() => {
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("current:user");
                    sessionStorage.removeItem('access_token');
                    
                    // Remove dark mode
                    if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

                    this.$router.replace("/authorization");
                });
            },
        },
        components: {
            BottomNavMenuItem,
            VerticalNavMenuLink
        },
                
        computed: {
            bottomNav() {
                
                let items = [];
                
                navMenuItems.forEach(item => {
                    if( item.side === 'bottom' ) {
                        items.push( item );
                    }
                });
                
                return items;
            },
            rightSideNav() {
                
                let items = [];
                
                navMenuItems.forEach(item => {
                    if( item.side === 'right' ) {
                        items.push( item );
                    }
                });
                
                return items;
            }
        },
        watch: {
            "skin" : function( val ) {
                store.commit('appConfig/UPDATE_SKIN', val)
            }
        },
        mounted() {
            
            
            var initCoordsState = {
                x: 0, y: 0
            }
            
            function animate(time) {
                requestAnimationFrame(animate)
                TWEEN.update(time)
            }
                        
            requestAnimationFrame(animate);

            this.$root.$off('bv::modal::show');
            this.$root.$off('bv::modal::hide');

            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                
                let nav = document.getElementById("bottom-nav");
                        
                if( nav ) {
                    const tween = new TWEEN.Tween( initCoordsState )
                            .to({
                                x: 0, 
                                y: 88
                            }, 355 ) 
                            .onUpdate(obj => {
                                initCoordsState = obj;
                                nav.style.setProperty('transform', `translate(${obj.x}px, ${obj.y}px)`)
                            })
                            .start();
                }
                
            });
            
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                 
                let nav = document.getElementById("bottom-nav");
                             
                if( nav ) {
                    const tween = new TWEEN.Tween( initCoordsState )
                        .to({ x: 0, y: 0 }, 355 ) 
                        .onUpdate(obj => {
                            initCoordsState = obj;
                            nav.style.setProperty('transform', `translate(${obj.x}px, ${obj.y}px)`)
                        })
                        .start();
                }
                
            });


        }

    }

</script>