<template>
    <div class="d-flex align-items-center">
        <template v-if="item.children">
            <b-dropdown
                v-bind="linkProps"
                variant="transparent"
                offset="-80"
                dropup
                no-caret
              >
                <template #button-content>
                    <div :class="isActive ? `menu-icon icon-${item.icon}-active` : `menu-icon icon-${item.icon}`"></div>
                    <span :class=" isActive ? `menu-link-active` : `menu-link-inactive`">{{ $t(item.title) }}</span>
                </template>
                
                <b-dropdown-item v-for="child in item.children" :to="{ name: child.route }">
                    <p class="text-black m-0 text-center" style="font-size: 15px;">{{ $t(child.title) }}</p>
                </b-dropdown-item>
                
            </b-dropdown>
        </template>
        <b-link
          v-else
          v-bind="linkProps"
        >
          <div :class="isActive ? `menu-icon icon-${item.icon}-active` : `menu-icon icon-${item.icon}`"></div>
          <span :class=" isActive ? `menu-link-active` : `menu-link-inactive`">{{ $t(item.title) }}</span>
        </b-link>
    </div>
</template>

<script>
    
    import useBottomNavMenuLink from './useBottomNavMenuLink'
    import mixinBottomNavMenuLink from './mixinBottomNavMenuLink'

    export default {
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        mixins: [ mixinBottomNavMenuLink ],
        setup(props) {
            const { isActive, linkProps, updateIsActive } = useBottomNavMenuLink(props.item)
            return {
              isActive,
              linkProps,
              updateIsActive,
            }
        },

    }

</script>