var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-layout training custom-layout h-100",class:[_vm.layoutClasses],attrs:{"data-col":_vm.isNavMenuHidden || !_vm.User.isAuth() || _vm.isHideMenu ? '1-column' : null}},[_c('Header'),(!_vm.isNavMenuHidden && _vm.User.isAuth() && !_vm.isHideMenu)?_c('vertical-nav-menu',{attrs:{"is-vertical-menu-active":_vm.isVerticalMenuActive,"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_vm._t("vertical-menu-header",null,null,slotProps)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"sidenav-overlay",class:_vm.overlayClasses,on:{"click":function($event){_vm.isVerticalMenuActive = false}}}),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c(_vm.layoutContentRenderer,{key:_vm.layoutContentRenderer === 'layout-content-renderer-left' ? _vm.$route.meta.navActiveLink || _vm.$route.name : null,tag:"component",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})],1),_c('showAt',{attrs:{"breakpoint":"mediumAndBelow"}},[_c('bottom-nav-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.User.isAuth()),expression:"User.isAuth()"}]})],1),_c('Footer'),_vm._t("customizer"),_c('div',{style:({
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 1000,
            transitionProperty: 'all',
            transitionDuration: '.22s',
            left: _vm.isVerticalMenuCollapsed ? '80px' : (_vm.User.isAuth() ? '224px' : '0px') ,
            transitionProperty: 'all',
            transitionDuration: '.35s',
            transform: _vm.cart.length > 0 && _vm.$route.name !== 'user-miners-checkout' ? 'translateY(0px)' : 'translateY(310px)'

        })},[_c('cart-view',{attrs:{"isVerticalMenuCollapsed":_vm.isVerticalMenuCollapsed}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }