<template>
  <li
    v-if="isCanVisibleMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <div :class="isActive ? `menu-icon icon-${item.icon}-active` : `menu-icon icon-${item.icon}`"  />
      <span class="menu-title" style='margin-left: 16px; line-height: 20px; margin-top: 3px;'>{{ $t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import User from "@/modules/user"

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
      
      isCanVisibleMenuLink( item ) {
          
        if( !User.isAuth() ) {
            if( item.visibility === "public" ) {
                return true;
            } else {
                return false;
            }
        }
        
        return true;
          
      },

      isDisabled( item ) {

        if (item.route == 'user-referral' && !User.self.is_referral) return true;

        return false;

      }
      
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
